.wrapper {
    display: grid;
    gap: 2.5rem;
  }
  
  .blac-and-white {
    filter: grayscale(1);
  }
  
  @media (min-width: 992px) {
    .mx-500 {
      max-width: 600px;
    }
  }

.blog-card{
  display: flex;
  width: 100%;
  justify-content: space-between;
}



/*only for higher than tablet*/
@media (min-width: 768px) {
  .pt-md-5{
    padding-top: 3rem!important;
  }
}

.badge{
  border-radius: 3rem;
  padding: .5rem 1rem;
  background-color: #000;
}

a.text-muted:hover{
  color: #000!important;
}

a.text-muted:hover svg{
  transform: translate(2px , -2px);
}

a.text-muted{
  transition-duration: .2s;
}

.my-card{
  padding: 2rem;
  border-radius:1.5rem;
  background-color: rgba(246, 247, 249, 0.75);
  box-shadow: inset 0 0 0 1px rgb(239,239,239);
  display: grid;
}

blockquote p {
  padding: 1rem;
  border-radius:1rem;
  background-color: rgba(246, 247, 249, 0.75);
  box-shadow: inset 0 0 0 1px rgb(239,239,239);
}

.bg-light{
  background-color: rgba(246, 247, 249, 0.75)!important;
}
  
.my-card-body{
  display: flex;
justify-content: space-between;
flex-direction: column;

}

  .languages-tools-mother {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: #f2f2f2;
    color: var(--bs-body-color);
  }
  
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .5rem .75rem;
  }
  
  .languages-tools-mother .badge {
    font-size: 1rem;
    font-weight: 300;
  }
  
  .menu {
    background-color: rgba(246, 247, 249, 0.75);
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    padding: .5rem;
    border-radius: 5rem;
    box-shadow: inset 0 0 0 1px rgb(239,239,239);
    display: flex;
    gap: 1.25rem;
    backdrop-filter: blur(6px) saturate(180%);
    -webkit-backdrop-filter: blur(6px) saturate(180%);
    z-index: 1;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    padding: .5rem;
    /*background-color: #3997B6;*/
    /*color: #fff;*/
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: #f6f7f9;
    font-size: 1.5rem;
    color: #6c757d;
  }
  
  .nav-link.active {
    background-color: #efefef;
    border-color: #fff;
    color: var(--bs-black);
  }
  
  .nav-link:hover {
    background-color: #f2f2f2;
  }
  
  @media (max-width: 576px) {
    .mobile-menu .nav-link:hover {
      background-color: #000000;
    }
  }
  
  .vr-hr {
    width: 1px;
    background-color: #e6e6e6;
    border-radius: 1rem;
  }
  
  .pb-10 {
    padding-bottom: 6rem;
  }
  
  .text-muted {
    --bs-text-opacity: 1;
    color: #575e65!important;

  }

  .post-wrapper{
    padding-top: 2rem;
  }

  .post-wrapper p{
    color: #575e65;
    line-height:1.7rem
  }

  .post-wrapper img{
    width: 100%;
    height: auto;
  }

  .post-wrapper h1{
    font-weight: bold;
    margin-bottom: 2rem;
    line-height: 150%;
  }
  
  .text-muted .border-dark {
    border-color: #575e65!important;
  }
  
  .partof-list li svg {
    transition-duration: .2s;
  }
  
  .my-popover {
    position: relative;
    /*display: inline-block;*/
  }
  
  .my-popover::after {
    content: attr(data-content);
    position: absolute;
    /*display: none;*/
    width: auto;
    padding: .5rem 1rem;
    background-color: #212529;
    color: #fff;
    text-align: center;
    border-radius: 5rem;
    z-index: -1;
    bottom: 100%;
    left: 50%;
    opacity: 0;
    /*transition: opacity 0.3s;*/
    transform: translateX(-50%);
    font-size: 1rem;
    transition-duration: .2s;
  }
  
  .my-popover:hover::after {
    display: block;
    opacity: 1;
    bottom: 150%;
    pointer-events: none;
  }
  
  .blac-and-white.object-fit-cover {
    object-fit: cover;
  }
  
  ul li::before {
    content: "\2022";
    color: #6c757d;
    margin-left: -1rem;
    display: inline-block;
    width: 1rem;
    font-weight: bold;
  }
  
  ul.partof-list {
    list-style: none;
    /*padding-left: 0;*/
  }
  
  li {
    margin-left: 1rem;
  }
  
  ::selection, ::-moz-selection {
    color: var(--bs-body-color);
    background-color: #f2f2f2;
  }
  
  p::selection, p::-moz-selection {
    color: #575e65;
    background-color: #f2f2f2;
  }
  
  .mobile-menu {
    display: flex;
    gap: 1.25rem;
  }
  
  @media (max-width: 576px) {
    .mobile-menu {
      position: fixed;
      flex-direction: column;
      bottom: 5rem;
      right: 0;
      background-color: rgb(33,37,41);
      border-radius: 4rem;
      padding: .5rem;
      /*display: none;*/
      opacity: 0;
      transform-origin: bottom;
      /*transform: translateY(100%);*/
      transition-duration: .2s;
      z-index: -1;
      pointer-events: none;
    }
  }
  
  .menu-toggle {
    /*padding: .5rem;*/
    display: grid;
    align-items: center;
    background: none;
    border: none;
    width: 42px;
    justify-content: center;
    background-color: var(--bs-body-color);
    color: #fff;
    border-radius: 100%;
  }
  
  @media (max-width: 576px) {
    .mobile-menu .my-popover::after {
      opacity: 0;
      bottom: 0;
      left: 0;
      transform: translateX(calc(-100% - 1rem));
      position: absolute;
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    .mobile-menu .nav-link {
      border: none;
      color: #fff;
    }
  }
  
  @media (max-width: 576px) {
    .mobile-menu.open {
      display: flex;
      opacity: 1;
      transform: rotate(0deg);
      right: 0;
      bottom: 5rem;
      pointer-events: all;
    }
  }
  
  .menu-toggle.open .icon-tabler-x {
    display: block;
  }
  
  .menu-toggle:not(.open) .icon-tabler-x {
    display: none;
  }
  
  .menu-toggle:not(.open) .icon-tabler-menu {
    display: block;
  }
  
  .menu-toggle.open .icon-tabler-menu {
    display: none;
  }
  
  .menu-toggle.open {
    transform: rotate(180deg);
  }
  
  .menu-toggle {
    transition-duration: .3s;
  }
  

  .my-bg-component{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0%;
    z-index: -1;
  }


  body {
    position: relative;
  }



  pre{
    background-color: rgba(246, 247, 249, 0.75);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: inset 0 0 0 1px rgb(239,239,239);
  }

  pre code {
    font-size: inherit;
    color: inherit;
    word-break: break-word;
    white-space: break-spaces;
  }




  ul{
    list-style: none;
  }

  ul li{
    color: #575e65!important;
  }